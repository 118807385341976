import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../wrappers/Layout"
import { useStoreContext } from "../wrappers/Shop"
import useProductInfo from "../components/useProductInfo"
import Image from "../components/Image"
import ProductCollection from "../components/ProductCollection"
import PriceTag, { getPrices } from "../components/PriceTag"
import RichText from "../components/RichText"
import useSizeConversion from "../components/useSizeConversion"
import isSizeOption from "../helpers/isSizeOption"
import { useTrackViewCart, trackRemoveFromCart } from "../components/useTrackingEvents"
import log from "../helpers/log"
import { BRAND } from "../helpers/constants"

export function useQuantity(initial) {
	const [quantity, setQuantity] = useState(initial)
	const up = () => setQuantity(prev => prev + 1)
	const down = () => setQuantity(prev => prev > 0 ? prev - 1 : 0)
	return [quantity, up, down]
}

const LineItemVariantOption = ({ name, value }) => {
	const isSize = isSizeOption(name)
	const { getSize } = useSizeConversion()

	return (
		<li>{name}: {isSize ? <span className="upcase">{getSize(value, { withSystem: true })}</span> : value}</li>
	)
}

const LineItem = ({ 
	lineItemId, 
	selectedOptions,
	quantity, 
	shopifyProduct, 
	trackingData
}) => {

	const { 
		loading, 
		updateLineItem 
	} = useStoreContext()

	const {
		title,
		handle,
		variants,
		userCurrency,
		productStyleName: style,
		productColourName: colour,
		productMaterialName: material,
		media
	} = useProductInfo(shopifyProduct)

	const increase = () => {
		updateLineItem(lineItemId, quantity + 1)
	}
	const decrease = () => {
		const newQuantity = quantity - 1
		updateLineItem(lineItemId, newQuantity, (updatedCart) => {
			if (newQuantity < 1) {
				trackRemoveFromCart({
					products: [trackingData],
					currencyCode: userCurrency?.currencyCode,
					list: trackingData.list,
					cart: updatedCart
				})
			}
		})
	}

	const cursorStyle = { cursor: loading ? 'wait' : 'pointer' }

	return (
		<div className="cart-line-item innerx2 bdr-b">
			<div className="flex-stretch">
				<Link to={`/products/${handle}`} className="aspect-1x1 bg-orange">
					<div className="fs object-cover">
						{media && <Image image={media[0]?.thumbnail} />}
					</div>
				</Link>
				<div className="guttersx2">
					<h2 className="">{style || title}</h2>
					<ul className="outerx2">
						{selectedOptions?.map(option => 
							<LineItemVariantOption {...option} key={option.name} />
						)}
					</ul>
					
				</div>
				<div className="guttersx2">
					<div className="mbx2">
						{/* Todo: use price object */}
						{userCurrency && variants && <PriceTag variant={variants[0]} userCurrency={userCurrency} />}
					</div>
					<div className="quantity-input">
						<button type="button" onClick={decrease} style={cursorStyle}>-</button>
						<span>{quantity}</span>
						<button type="button" onClick={increase} style={cursorStyle}>+</button>
					</div>
				</div>
			</div>
		</div>
	)
}

const CartItems = ({ lineItems }) => {

	return (
		lineItems.map((item) => {
			return (
				item?.shopifyProduct ? (
					<LineItem 
						{...item}
						key={item.id} 
					/>
				) : null
			)
		})
	)
}

const CheckoutButton = ({ href }) => {
	// const url = href.replace(process.env.GATSBY_SHOPIFY_STORE_URL, "harrysldn.myshopify.com").replace('https://', '//') // trying out this last replace to see if the redirects are related to Jakes issue...
	// Will turn this off and use {href} later as still double-checking that the DNS/SSL issue Jake is seeing is unique to him..
	// log(url)
	return (
		<a href={href} className="btn">Checkout</a>
	)
}

const CartPage = ({ data, location }) => {

	const { cart } = useStoreContext()

	// console.log('------- CART START ------')
	// console.log(cart)

	const trackingList = location?.state?.list

	const lineItems = cart ? cart.lines?.edges?.map(({ node }, index) => {
		const item = node.merchandise
		const $findVariant = v => v.shopifyId === item?.id
		const matchingProduct = data.allShopifyProduct?.nodes?.find(p => p.variants.find($findVariant))
		const list = node?.attributes?.find(a => a.key === 'Collection')?.value || ""
		
		const trackingData = {
			id: item.sku, // SKU
			name: item?.product?.title, // Product title
			brand: BRAND,
			category: matchingProduct?.vendor,
			variant: item.title,
			price: item.price.amount,
			compare_at_price: item.compareAtPrice?.amount || "0.0",
			quantity: `${node.quantity}`,
			list: list,
			product_id: item.product.id?.split('/Product/')?.[1], // The product_id
			variant_id: item.id?.split('/ProductVariant/')?.[1], // id or variant_id
			position: index+1,
			image: matchingProduct?.media?.[0]?.thumbnail?.images?.fallback?.src || "", // If available, otherwise use an empty string
		}

		return {
			...item,
			lineItemId: node.id,
			quantity: `${node.quantity}`,
			shopifyProduct: matchingProduct,
			trackingData
		}
	}) : []

	log(lineItems)

	const hasLineItems = lineItems?.length > 0
	const totalQuantity = cart?.totalQuantity || 0

	const [checkoutTotal] = cart?.cost?.totalAmount ? getPrices(cart.cost.totalAmount) : []

	const trackingData = cart?.id ? {
		currencyCode: checkoutTotal?.price?.currencyCode,
		cart_total: checkoutTotal?.price?.value,
		list: trackingList,
		impressions: lineItems?.map((item) => item.trackingData),
		cart: cart
	} : null
	useTrackViewCart(trackingData, [cart?.id])

	return (
		<Layout title={cart?.id ? `Cart (${totalQuantity}) | Harrys London` : `Cart | Harrys London`} location={location}>
			{cart?.id && hasLineItems ? (
				<>
					<div className="align-center page-gutters innerx4">
						<h1>{hasLineItems ? `Shopping Bag (${totalQuantity})` : cart?.id ? 'Your bag is empty' : 'Loading…'}</h1>
					</div>
					<div className="grid gapsx4 page-gutters">
						<div className="span-8">
							<CartItems lineItems={lineItems} />
						</div>
						{cart?.id && (
							<div className="span-4 cart-checkout">
								<div className="padx2 bg-sand">
									<div className="cart-checkout-info">
										<div className="flex-spaced">
											<span>Subtotal:</span>
											<span>{`${checkoutTotal?.price?.amount} ${checkoutTotal?.price?.currencyCode}`}</span>
										</div>
									</div>
									<div className="outerx2 p3 has-links">
										<RichText content={data.allContentfulSettings.nodes[0].checkoutSmallprint} />
									</div>
									<CheckoutButton href={cart?.checkoutUrl} />
								</div>
							</div>
						)}
					</div>	
				</>	
			) : cart?.id ? (
				<ProductCollection products={data.allShopifyProduct?.nodes} location={location}>
					<div className="align-center page-gutters innerx4">
						<h1>{cart?.id ? 'Your bag is empty' : 'Loading…'}</h1>
					</div>
				</ProductCollection>
			) : (
				<div className="align-center page-gutters innerx4">
					<h1>{'Loading…'}</h1>
				</div>
			)}
		</Layout>
	)
}

CartPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default CartPage

export const query = graphql`
  query {
		allContentfulSettings(limit: 1) {
			nodes {
				checkoutSmallprint {
					raw
				}
			}
		}
    allShopifyProduct(
      sort: { fields: updatedAt, order: ASC }
    ) {
      nodes {
        ...ProductLink
				vendor
      }
    }
  }
`
